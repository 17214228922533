export const USER_GROUP = {
  VROAR: "VROAR",
  PARENT: "PARENT",
  STUDENT: "STUDENT",
  COMPANY: "COMPANY",
  COMPANY_STAFF: "COMPANY_STAFF",
  VROAR_STAFF: "VROAR_STAFF",
};
export const USER_PERMISSION = {
  READ: "READ",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const colors = {
  transparent: "transparent",
  primary: "#f15d17",
  linearGradient: "linear-gradient(#f15b65, #fcb52e)",
};

export const USER_STATUS = {
  ALL: "ALL",
  ACTIVE: "ACTIVE",
  BANNED: "BANNED",
  InActive: "INACTIVE",
};

export const SORT = {
  NAME: "Name",
  LASTLOGIN: "Last Login",
};

export const SUBSCRIPTION_PLAN_TYPE = {
  COURSE: "COURSE",
  INTERNSHIP: "INTERNSHIP",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  FIXED: "FIXED",
};

export const PROMOTION_TYPE = {
  VOUCHER: "VOUCHER",
  DISCOUNT: "DISCOUNT",
  PROMOTION: "PROMOTION",
  OFFER: "OFFER",
};

export const PROMOTION_SCENARIOS = {
  FIRST_TIME_LOGIN: "FIRST_TIME_LOGIN",
  SUBSCRIPTION: "SUBSCRIPTION",
  ALL: "ALL",
};

export const fontFamily = {
  roboto: "roboto_slab",
  plus: "plus",
};

export const SUBSCRIPTION_TIER = {
  BASIC: "BASIC",
  PREMIUM: "PREMIUM",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE",
};
