import { colors } from "./enum";

export const loginTextField = {
  "& label.Mui-focused": {
    color: "#000000",
  },
  "& label": {
    fontSize: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #d7d7d7",
      padding: "10px !important",
    },
    "&:hover fieldset": {
      borderColor: "#000000",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #000000",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px",
  },

  "& .Mui-error": {
    "& fieldset": {
      border: "1px solid #d32f2f",
    },
    "&:hover fieldset": {
      border: "1px solid #d32f2f",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #d32f2f",
    },
    "& label.Mui-focused": {
      color: "#d32f2f",
    },
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  // mb: 2,
};
export const tabSection = {
  "& .MuiTab-root": {
    ":hover": {
      color: "#f15d17",
      backgroundColor: "transparent",
    },
  },
  "& .Mui-selected": {
    color: `${colors.primary} !important`,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: `${colors.primary} !important`,
  },
};
