import {
  DISCOUNT_TYPE,
  PROMOTION_SCENARIOS,
  PROMOTION_TYPE,
  SUBSCRIPTION_TIER,
  USER_STATUS,
} from "./enum";

export const promotionList = [
  {
    label: PROMOTION_TYPE.DISCOUNT,
  },
  {
    label: PROMOTION_TYPE.OFFER,
  },
  {
    label: PROMOTION_TYPE.PROMOTION,
  },
  {
    label: PROMOTION_TYPE.VOUCHER,
  },
];

export const discountType = [
  {
    label: DISCOUNT_TYPE.PERCENTAGE,
  },
  {
    label: DISCOUNT_TYPE.FIXED,
  },
];

export const promotionScenariosList = [
  {
    label: PROMOTION_SCENARIOS.ALL,
  },
  {
    label: PROMOTION_SCENARIOS.FIRST_TIME_LOGIN,
  },
  {
    label: PROMOTION_SCENARIOS.SUBSCRIPTION,
  },
];

export const tabs = [
  {
    label: USER_STATUS.ALL,
  },
  {
    label: USER_STATUS.ACTIVE,
  },
  {
    label: USER_STATUS.InActive,
  },
];

export const tableHeader = [
  {
    name: "Name",
    icon: true,
  },

  {
    name: "Phone No",
    icon: false,
  },

  {
    name: "Registered On",
    icon: true,
  },
  {
    name: "Last Login",
    icon: false,
  },
  {
    name: "Status",
    icon: false,
  },
  // {
  //   name: "Actions",
  //   icon: false,
  // },
];

export const subscriptionStatus = [
  { label: USER_STATUS.ACTIVE },
  { label: USER_STATUS.InActive },
];

export const subscriptionTierList = [
  {
    label: SUBSCRIPTION_TIER.BASIC,
  },
  {
    label: SUBSCRIPTION_TIER.PREMIUM,
  },
];
