import { ArrowUpward, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

const UserCard = ({ icon, heading, userCount, newUserCount, path }) => {
  const navigate = useNavigate();

  const routeAnotherPage = (path) => {
    navigate(path);
  };
  return (
    <div>
      <Card>
        <CardActionArea
          sx={{
            p: 2,
            ":hover": {
              backgroundColor: "#00000019",
            },
            transition: "0.5s ease all",
          }}
          onClick={() => routeAnotherPage(path)}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <Avatar
              sx={{
                width: 50,
                height: 50,
                borderRadius: 2,
                backgroundColor: "#FFEEC3",
                color: "#FFB800",
              }}
            >
              {icon}
            </Avatar>
            <Box>
              <Typography sx={{ fontSize: 12, color: "#00000069" }}>
                {heading}
              </Typography>
              <Typography fontSize={15} fontWeight={600}>
                {userCount}
              </Typography>
              <Typography sx={{ fontSize: 12, color: "#00000069" }}>
                {moment().format("MMMM")}, {moment().format("YYYY")}
              </Typography>
              <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography color={"#008000"} fontSize={12}>
                  {newUserCount}
                </Typography>
                <ArrowUpward sx={{ fontSize: 12, color: "#008000" }} />
              </Stack>
            </Box>
          </Stack>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default UserCard;
