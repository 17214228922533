import React from "react";
// import AppContainer from './appcontainer.jsx';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Route, BrowserRouter as Router, Routes,useLocation  } from "react-router-dom";
import "./App.css";
import Dashboard from "./dashboard/Index";
import Customers from "./customers/Index";
import AddCustomer from "./customers/AddCustomer";
import EditCustomer from "./customers/EditCustomer";
import Expenses from "./expenses/Index";
import AddExpenses from "./expenses/AddExpenses";
import EditExpenses from "./expenses/EditExpenses";
import Estimates from "./estimates/Index";
import AddEstimate from "./estimates/AddEstimate";
import EditEstimate from "./estimates/EditEstimate";
import ViewEstimate from "./estimates/ViewEstimate";
import Invoices from "./invoices/Index";
import Invoicedetails from "./invoices/Invoicedetails";
import Invoicesettings from "./invoices/Invoicesettings";
import Invoicegrid from "./invoices/Invoicegrid";
import Taxsettings from "./invoices/Taxsettings";
import Invoicerecurring from "./invoices/Invoicerecurring";
import Invoicecancelled from "./invoices/Invoicecancelled";
import Invoicepaid from "./invoices/Invoicepaid";
import Invoiceoverdue from "./invoices/Invoiceoverdue";
import Banksettings from "./invoices/Banksettings";
import ViewInvoice from "./invoices/ViewInvoice";
import AddInvoice from "./invoices/AddInvoice";
import EditInvoice from "./invoices/EditInvoice";
import Invoicedraft from "./invoices/Invoicedraft";
import Payments from "./payments/Index";
import AddPayments from "./payments/AddPayments";
import ProfileSettings from "./settings/Profile";
import Preferences from "./settings/Preferences";
import TaxTypes from "./settings/TaxTypes";
import ExpenseCategory from "./settings/ExpenseCategory";
import Notifications from "./settings/Notifications";
// import ChangePassword from "./settings/ChangePassword";
import Chat from "./application/Chat";
import Calendar from "./application/Calendar";
import Inbox from "./application/Inbox";
import Profile from "./profile/Index";
import Login from "./authentication/Login";
import Register from "./authentication/Register";
import ForgotPassword from "./authentication/ForgotPassword";
import LockScreen from "./authentication/LockScreen";
import Page404 from "./errorpages/404";
import Page500 from "./errorpages/500";
import BlankPage from "./blankpage/Index";
import Components from "./components/Index";

import VectorMaps from "./vectormaps/Index";
import BasicTables from "./tables/BasicTables";
import DataTables from "./tables/DataTables";
import SalesReport from "./reports/salesreport";
import ExpenseReport from "./reports/expense";
import ProfitlossReport from "./reports/profitloss";
import Taxreport from "./reports/taxreport";
import Invoiceone from "./invoices/Invoiceone";
import InvoiceTwo from "./invoices/InvoiceTwo";
import BusTicket from "./invoices/BusTicket";
import CarBookingInvoice from "./invoices/CarBookingInvoice";
import CoffeeShop from "./invoices/CoffeeShop";
import CashRecepitOne from "./invoices/CashRecepit";
import CashRecepitTwo from "./invoices/CashRecepitTwo";
import CashRecepitThree from "./invoices/CashRecepitThree";
import CashRecepitFour from "./invoices/CashRecepitFour";
import AddProduct from "./products/addProduct";
import CustomerDetails from "./customers/customerDetails";
import CustomerDetailsPaid from "./customers/customerDetailsPaid";
import CustomerDetailsCancel from "./customers/customerDetailsCancel";
import Vendors from "./vendors";
import AddLedger from "./vendors/addLedger";
import ProductList from "./products/productList";
import EditProduct from "./products/editProduct";
import Category from "./products/category";
import Units from "./products/units";
import Inventory from "./inventory";
import ActiveCustomers from "./customers/activeCustomers";
import DeactiveCustomers from "./customers/deactivateCustomers";
import InvoiceList from "./invoices/invoiceList";
import InvoiceOverdue from "./invoices/Invoiceoverdue";
import InvoicePaid from "./invoices/Invoicepaid";
import InvoiceTemplate from "./invoices/invoiceTemplate";
import GeneralInvoiceOne from "./invoices/generalInvoiceOne";
import GeneralInvoiceTwo from "./invoices/generalInvoiceTwo";
import GeneralInvoiceFour from "./invoices/generalInvoiceFour";
import GeneralInvoiceThree from "./invoices/generalInvoiceThree";
import GeneralInvoiceFive from "./invoices/generalInvoiceFive";
import DomainHosting from "./invoices/domainHosting";
import Ecommerce from "./invoices/ecommerce";
import FitnessCenter from "./invoices/fitnessCenter";
import TrainTicketBooking from "./invoices/trainTicketBooking";
import FlightBookingInvoice from "./invoices/flightBookingInvoice";
import HotelBooking from "./invoices/hotelBooking";
import InternetBooking from "./invoices/internetBooking";
import Medical from "./invoices/medical";
import MoneyExchange from "./invoices/moneyExchange";
import MovieTicketBooking from "./invoices/movieTicketBooking";
import RestuarentBilling from "./invoices/restuarentBilling";
import StudentBilling from "./invoices/studentBilling";
import RecurringInvoice from "./recurring";
import RecurringPaid from "./recurring/recurringPaid";
import RecurringPending from "./recurring/recurringPending";
import RecurringOverdue from "./recurring/recurringOverdue";
import RecurringDraft from "./recurring/recurringDraft";
import RecurringCancelled from "./recurring/recurringCancelled";
import Recurring from "./recurring/recurring";
import CreditNotes from "./creditNotes";
import CreditPending from "./creditNotes/creditPending";
import CreditOverdue from "./creditNotes/creditOverdue";
import CreditDraft from "./creditNotes/creditDraft";
import CreditRecurring from "./creditNotes/creditRecurring";
import CreditCancelled from "./creditNotes/creditCancelled";
import AddCredit from "./creditNotes/addCredit";
import CreditDetails from "./creditNotes/creditDetails";
import Purchases from "./purchase";
import AddPurchases from "./purchase/addPurchases";
import EditPurchase from "./purchase/editPurchase";
import PurchaseDetails from "./purchase/purchaseDetails";
import PurchaseOrders from "./purchaseOrders";
import DebitNotes from "./debitNotes";
import Quotations from "./quatations";
import AddQuotations from "./quatations/addQuotations";
import EditQuotations from "./quatations/editQuotations";
import DeliveryChallans from "./deliveryChallans";
import EditChallans from "./deliveryChallans/editChallans";
import AddChallans from "./deliveryChallans/addChallans";
import PaymentSummary from "./paymentSummary";
import AddUser from "./manageUser";
import User from "./manageUser/user";
import Users from "./manageUser/user";
import EditUser from "./manageUser/editUser";
import RolesPermission from "./rolePermission";
import Permission from "./rolePermission/permission";
import DeleteAccount from "./deleteAccount";
import MembershipPlan from "./membership";
import MembershipAddons from "./membership/membershipAddons";
import Subscribers from "./membership/subscribers";
import Transaction from "./membership/transaction";
import AddPage from "./pages";
import Pages from "./pages/page";
import AllBlogs from "./blog";
import InactiveBlog from "./blog/inactiveBlog";
import AddBlog from "./blog/addBlog";
import Categories from "./blog/categories";
import AddCategories from "./blog/addCategories";
import BlogComments from "./blog/blogComments";
import Countries from "./location";
import States from "./location/states";
import Cities from "./location/cities";
import Testimonials from "./testimonials";
import AddTestimonials from "./testimonials/addTestimonials";
import EditTestimonials from "./testimonials/editTestimonials";
import Faq from "./faq";
import Tickets from "./tickets";
import TicketPending from "./tickets/ticketPending";
import TicketOverdue from "./tickets/ticketOverdue";
import TicketDraft from "./tickets/ticketDraft";
import TicketRecurring from "./tickets/ticketRecurring";
import TicketCancelled from "./tickets/ticketCancelled";
import TicketList from "./tickets/ticketlist";
import TicketListPending from "./tickets/ticketlist/ticketListPending";
import TicketListOverdue from "./tickets/ticketlist/ticketListOverdue";
import TicketListDraft from "./tickets/ticketlist/ticketListdraft";
import TicketListRecurring from "./tickets/ticketlist/ticketListRecurring";
import TicketListCancelled from "./tickets/ticketlist/ticketListCancelled";
import TicketKanban from "./tickets/ticketKanban";
import TicketDetails from "./tickets/ticketOverview";
import { FullScreen } from "react-full-screen";
import AddMembership from "./membership/addMembership";
import FontAwesome from "./icons/font-awesome";
import Feather from "./icons/feather";
import IconicIcon from "./icons/iconic";
import MaterialIcon from "./icons/material";
import MaterialIcons from "./icons/material";
import Pe7 from "./icons/pe7";
import SimpleLine from "./icons/simpleLine";
import Themify from "./icons/themify";
import Apexchart from "./charts/apexcharts";
import ChartJs from "./charts/chartjs";
import MorrisCharts from "./charts/morrisChart";
import FlotCharts from "./charts/floatjs";
import PeityCharts from "./charts/peityCharts";
import C3Charts from "./charts/c3Charts";
import Flags from "./icons/flags";
import WeatherIcons from "./icons/weather";
import Accordions from "./baseUi/accordions";
import Alert from "./baseUi/alerts";
import Avatar from "./baseUi/avatar";
import Badges from "./baseUi/badges";
import Buttons from "./baseUi/buttons";
import Buttongroup from "./baseUi/button-group";
import Breadcumbs from "./baseUi/breadcrumbs";
import Cards from "./baseUi/cards";
import Carousel from "./baseUi/carousel";
// import Ribbon from "./elements/ribbon";
import Dropdowns from "./baseUi/dropdowns";
import Grid from "./baseUi/grid";
import Images from "./baseUi/images";
import Lightbox from "./baseUi/lightbox";
import Media from "./baseUi/media";
import Modals from "./baseUi/modals";
import Offcanvas from "./baseUi/offcanvas";
import Pagination from "./baseUi/pagination";
import Popover from "./baseUi/popover";
import Progress from "./baseUi/progress";
import Placeholder from "./baseUi/placeholders";
import Rangeslides from "./baseUi/rangeslider";
import Spinners from "./baseUi/spinners";
import Sweetalerts from "./baseUi/sweeetalerts";
import Tap from "./baseUi/tab";
import Toasts from "./baseUi/toasts";
import Tooltip from "./baseUi/tooltip";
import Typography from "./baseUi/typography";
import Videos from "./baseUi/videos";
import Error404 from "./error404";
import Fileupload from "./forms/File-upload";
import Formselect2 from "./forms/form-select2";
import BasicInputs from "./forms/basic-inputs";
import FormInputGroups from "./forms/input-groups";
import HorizontalForm from "./forms/horizontal-form";
import VerticalForm from "./forms/vertical-form";
import FormMask from "./forms/FormMask";
import FormValidation from "./forms/FormValidation";
import DeleteAccounts from "./settings/deleteAccount";

import Ribbon from "./elements/ribbon";
import { Clipboard } from "./elements/clipboard";
import Rating from "./elements/rating";
import Texteditor from "./elements/texteditor";
import Counter from "./elements/counter";
import Scrollbar from "./elements/scrollbar";
import Notification from "./elements/notification";
import Stickynote from "./elements/stickynote";
import Timeline from "./elements/timeline";
import Horizontaltimeline from "./elements/horizontaltimeline";
import Formwizard from "./elements/formwizard";
import ContactMessage from "./contactMessage";
import DragDrop from "./elements/drag&drop";
import TypiconIcons from "./icons/typicon";
import Modules from "./application/Modules";
import CreateModule from "./Createmodules/module";
import Student from "./_components/student/index.jsx";
import Company_list from "./_components/company";
import Parent_list from "./_components/Parent/index.jsx";
import Profilepage from "./_components/profilepage";
import AddUsersVendor from "./_components/usersrolefiltter";
import Vroar_staff from "./_components/staff";
import RoleManagement from "./role-management";
import Modal from "./_components/customModal";
import AddRole from "./addRole";
import StaffManagement from "./staff-management";
import ChangePassword from "./changePassword";
import RoleDetails from "./role-details";
import Curriculum from "./curriculum-management/index.jsx";
import AddCurriculum from "./curriculum-management/add-curriculum.jsx";
import EditCurriculum from "./curriculum-management/edit-curriculum.jsx";
import SubscriptionManagement from "./subscription-management/index.jsx";
import AddSubscription from "./subscription-management/add-subscription.jsx";
import EditSubscription from "./subscription-management/edit-subscription.jsx";
import SubscriptionDetails from "./subscription-management/subscriptionDetails.jsx";
import PromotionManagement from "./coupon-management/index.jsx";
import AddCoupon from "./coupon-management/add-coupon.jsx";
import EditCoupon from "./coupon-management/edit-coupon.jsx";
import InternshipManagement from "./internship-management/index.jsx";
// import {ToastContainer, toast} from "react-toastify";

const AppRouter = (props) => {
  return (
    <Router>
      <Modal />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/modules" element={<Modules />} />
        <Route exact path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/createModule" element={<CreateModule />} />
        {/* <Route path='/react-toastify'element={<ToastContainer/>}/> */}
        <Route path="/student" element={<Student />} />
        <Route path="/staff" element={<Vroar_staff />} />
        <Route path="/company" element={<Company_list />} />
        <Route path="/parent" element={<Parent_list />} />
        <Route path="/profilepage" element={<Profilepage />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/add-customer" element={<AddCustomer />} />
        <Route path="/edit-customer" element={<EditCustomer />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/add-expenses" element={<AddExpenses />} />
        <Route path="/edit-expenses" element={<EditExpenses />} />
        <Route path="/estimates" element={<Estimates />} />
        <Route path="/add-estimate" element={<AddEstimate />} />
        <Route path="/edit-estimate" element={<EditEstimate />} />
        <Route path="/view-estimate" element={<ViewEstimate />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoice-details" element={<Invoicedetails />} />
        <Route path="/invoice-grid" element={<Invoicegrid />} />
        <Route path="/view-invoice" element={<ViewInvoice />} />
        <Route path="/add-invoice" element={<AddInvoice />} />
        <Route path="/edit-invoice" element={<EditInvoice />} />
        <Route path="/tax-settings" element={<Taxsettings />} />
        <Route path="/invoice-paid" element={<InvoicePaid />} />
        <Route path="/invoice-overdue" element={<InvoiceOverdue />} />
        <Route path="/bank-settings" element={<Banksettings />} />
        <Route path="/invoices-settings" element={<Invoicesettings />} />
        <Route path="/invoice-draft" element={<Invoicedraft />} />
        <Route path="/invoice-recurring" element={<Invoicerecurring />} />
        <Route path="/invoice-cancelled" element={<Invoicecancelled />} />
        <Route path="/invoice-one" element={<Invoiceone />} />
        <Route path="/invoice-two" element={<InvoiceTwo />} />

        <Route path="/payments" element={<Payments />} />
        <Route path="/add-payments" element={<AddPayments />} />
        <Route path="/settings" element={<ProfileSettings />} />
        <Route path="/sales-report" element={<SalesReport />} />
        <Route path="/expenses-report" element={<ExpenseReport />} />
        <Route path="/profit-loss-report" element={<ProfitlossReport />} />
        <Route path="/taxs-report" element={<Taxreport />} />
        <Route path="/preferences" element={<Preferences />} />
        <Route path="/tax-types" element={<TaxTypes />} />
        <Route path="/expense-category" element={<ExpenseCategory />} />
        <Route path="/notifications" element={<Notifications />} />
        {/* <Route path="/change-password" element={<ChangePassword />} /> */}
        <Route path="/chat" element={<Chat />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/error-404" element={<Page404 />} />
        <Route path="/error-500" element={<Page500 />} />
        <Route path="/blank-page" element={<BlankPage />} />
        <Route path="/components" element={<Components />} />
        <Route path="/basic-inputs" element={<BasicInputs />} />
        <Route path="/input-groups" element={<FormInputGroups />} />
        <Route path="/horizontal-form" element={<HorizontalForm />} />
        <Route path="/vertical-form" element={<VerticalForm />} />
        <Route path="/form-mask" element={<FormMask />} />
        <Route path="/form-validation" element={<FormValidation />} />
        <Route path="/File-upload" element={<Fileupload />} />
        <Route path="/form-select2" element={<Formselect2 />} />
        <Route path="/basic-table" element={<BasicTables />} />
        <Route path="/data-tables" element={<DataTables />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/lock-screen" element={<LockScreen />} />
        <Route path="/bus-ticket" element={<BusTicket />} />
        <Route path="/car-booking-invoice" element={<CarBookingInvoice />} />
        <Route path="/coffee-shop" element={<CoffeeShop />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/edit-product" element={<EditProduct />} />
        <Route path="/active-customers" element={<ActiveCustomers />} />
        <Route path="/deactive-customers" element={<DeactiveCustomers />} />
        <Route path="/customer-details" element={<CustomerDetails />} />
        <Route
          path="/customer-details-paid"
          element={<CustomerDetailsPaid />}
        />
        <Route
          path="/customer-details-cancel"
          element={<CustomerDetailsCancel />}
        />
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/add-ledger" element={<AddLedger />} />
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/category" element={<Category />} />
        <Route path="/units" element={<Units />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/invoice-list" element={<InvoiceList />} />
        <Route path="/invoice-template" element={<InvoiceTemplate />} />
        <Route path="/general-invoice-1" element={<GeneralInvoiceOne />} />
        <Route path="/general-invoice-2" element={<GeneralInvoiceTwo />} />
        <Route path="/general-invoice-3" element={<GeneralInvoiceThree />} />
        <Route path="/general-invoice-4" element={<GeneralInvoiceFour />} />
        <Route path="/general-invoice-5" element={<GeneralInvoiceFive />} />
        <Route path="/domain-hosting" element={<DomainHosting />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/fitness-center" element={<FitnessCenter />} />
        <Route path="/train-ticket-booking" element={<TrainTicketBooking />} />
        <Route
          path="/flight-booking-invoice"
          element={<FlightBookingInvoice />}
        />
        <Route path="/hotel-booking" element={<HotelBooking />} />
        <Route path="/internet-billing" element={<InternetBooking />} />
        <Route path="/medical" element={<Medical />} />
        <Route path="/moneyexchange" element={<MoneyExchange />} />
        <Route path="/movie-ticket-booking" element={<MovieTicketBooking />} />
        <Route path="/restuarent-billing" element={<RestuarentBilling />} />
        <Route path="/student-billing" element={<StudentBilling />} />
        <Route path="/cashreceipt-1" element={<CashRecepitOne />} />
        <Route path="/cashreceipt-2" element={<CashRecepitTwo />} />
        <Route path="/cashreceipt-3" element={<CashRecepitThree />} />
        <Route path="/cashreceipt-4" element={<CashRecepitFour />} />
        <Route path="/recurring-invoices" element={<RecurringInvoice />} />
        <Route path="/recurring-paid" element={<RecurringPaid />} />
        <Route path="/recurring-pending" element={<RecurringPending />} />
        <Route path="/recurring-overdue" element={<RecurringOverdue />} />
        <Route path="/recurring-draft" element={<RecurringDraft />} />
        <Route path="/recurring-cancelled" element={<RecurringCancelled />} />
        <Route path="/recurring" element={<Recurring />} />
        <Route path="/credit-notes" element={<CreditNotes />} />
        <Route path="/credit-notes-pending" element={<CreditPending />} />
        <Route path="/credit-notes-overdue" element={<CreditOverdue />} />
        <Route path="/credit-notes-draft" element={<CreditDraft />} />
        <Route path="/credit-notes-recurring" element={<CreditRecurring />} />
        <Route path="/credit-notes-cancelled" element={<CreditCancelled />} />
        <Route path="/credit-notes-cancelled" element={<CreditCancelled />} />
        <Route path="/add-credit-notes" element={<AddCredit />} />
        <Route path="/credit-notes-details" element={<CreditDetails />} />
        <Route path="/purchases" element={<Purchases />} />
        <Route path="/add-purchases" element={<AddPurchases />} />
        <Route path="/edit-purchases" element={<EditPurchase />} />
        <Route path="/purchases-details" element={<PurchaseDetails />} />
        <Route path="/purchase-orders" element={<PurchaseOrders />} />
        <Route path="/debit-notes" element={<DebitNotes />} />
        <Route path="/quotations" element={<Quotations />} />
        <Route path="/add-quotations" element={<AddQuotations />} />
        <Route path="/edit-quotations" element={<EditQuotations />} />
        <Route path="/delivery-challans" element={<DeliveryChallans />} />
        <Route path="/edit-delivery-challans" element={<EditChallans />} />
        <Route path="/add-delivery-challans" element={<AddChallans />} />
        <Route path="/payment-summary" element={<PaymentSummary />} />
        <Route path="/add-user" element={<AddUser />} />
        <Route path="/users" element={<Users />} />
        <Route path="/edit-users" element={<EditUser />} />
        <Route path="/roles-permission" element={<RolesPermission />} />
        <Route path="/permission" element={<Permission />} />
        <Route path="/permission" element={<Permission />} />
        <Route path="/delete-account-request" element={<DeleteAccount />} />
        <Route path="/membership-plans" element={<MembershipPlan />} />
        <Route path="/membership-addons" element={<MembershipAddons />} />
        <Route path="/subscribers" element={<Subscribers />} />
        <Route path="/transactions" element={<Transaction />} />
        <Route path="/add-page" element={<AddPage />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/all-blogs" element={<AllBlogs />} />
        <Route path="/inactive-blog" element={<InactiveBlog />} />
        <Route path="/add-blog" element={<AddBlog />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/add-categories" element={<AddCategories />} />
        <Route path="/blog-comments" element={<BlogComments />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/states" element={<States />} />
        <Route path="/cities" element={<Cities />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/add-testimonials" element={<AddTestimonials />} />
        <Route path="/edit-testimonials" element={<EditTestimonials />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/tickets-list-pending" element={<TicketPending />} />
        <Route path="/tickets-list-overdue" element={<TicketOverdue />} />
        <Route path="/tickets-list-draft" element={<TicketDraft />} />
        <Route path="/tickets-list-recurring" element={<TicketRecurring />} />
        <Route path="/tickets-list-cancelled" element={<TicketCancelled />} />
        <Route path="/tickets-list" element={<TicketList />} />
        <Route path="/tickets-pending" element={<TicketListPending />} />
        <Route path="/tickets-overdue" element={<TicketListOverdue />} />
        <Route path="/tickets-draft" element={<TicketListDraft />} />
        <Route path="/tickets-recurring" element={<TicketListRecurring />} />
        <Route path="/tickets-cancelled" element={<TicketListCancelled />} />
        <Route path="/tickets-kanban" element={<TicketKanban />} />
        <Route path="/tickets-overview" element={<TicketDetails />} />
        <Route path="/add-membership" element={<AddMembership />} />
        <Route path="/add-membership" element={<AddMembership />} />
        <Route path="/fontawesome-icons" element={<FontAwesome />} />
        <Route path="/feather-icons" element={<Feather />} />
        <Route path="/ionic-icons" element={<IconicIcon />} />
        <Route path="/material-icons" element={<MaterialIcons />} />
        <Route path="/pe7-icons" element={<Pe7 />} />
        <Route path="/typicon-icons" element={<TypiconIcons />} />
        <Route path="/simpleline-icons" element={<SimpleLine />} />
        <Route path="/themify-icons" element={<Themify />} />
        <Route path="/weather-icons" element={<WeatherIcons />} />
        <Route path="/flag-icons" element={<Flags />} />
        <Route path="/apex-charts" element={<Apexchart />} />
        <Route path="/chart-js" element={<ChartJs />} />
        <Route path="/morris-charts" element={<MorrisCharts />} />
        <Route path="/float-charts" element={<FlotCharts />} />
        <Route path="/peity-charts" element={<PeityCharts />} />
        <Route path="/c3-charts" element={<C3Charts />} />

        <Route path="/AddUsersVendor" element={<AddUsersVendor />} />

        <Route path="/alerts" element={<Alert />} />
        <Route path="/accordions" element={<Accordions />} />
        <Route path="/avatar" element={<Avatar />} />
        <Route path="/badges" element={<Badges />} />
        <Route path="/buttons" element={<Buttons />} />
        <Route path="/button-group" element={<Buttongroup />} />
        <Route path="/breadcrumbs" element={<Breadcumbs />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/carousel" element={<Carousel />} />
        <Route path="/dropdowns" element={<Dropdowns />} />
        <Route path="/grid" element={<Grid />} />
        <Route path="/images" element={<Images />} />
        <Route path="/lightbox" element={<Lightbox />} />
        <Route path="/media" element={<Media />} />
        <Route path="/modals" element={<Modals />} />
        <Route path="/offcanvas" element={<Offcanvas />} />
        <Route path="/pagination" element={<Pagination />} />
        <Route path="/popover" element={<Popover />} />
        <Route path="/progress" element={<Progress />} />
        <Route path="/placeholders" element={<Placeholder />} />
        <Route path="/rangeslides" element={<Rangeslides />} />
        <Route path="/spinners" element={<Spinners />} />
        <Route path="/sweetalerts" element={<Sweetalerts />} />
        <Route path="/tab" element={<Tap />} />
        <Route path="/toasts" element={<Toasts />} />
        <Route path="/tooltip" element={<Tooltip />} />
        <Route path="/typography" element={<Typography />} />
        <Route path="/video" element={<Videos />} />
        <Route path="/vector-map" element={<VectorMaps />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/delete-accounts" element={<DeleteAccounts />} />

        <Route path="/ribbon" element={<Ribbon />} />
        <Route path="/clipboard" element={<Clipboard />} />
        <Route path="/drag-drop" element={<DragDrop />} />
        <Route path="/rating" element={<Rating />} />
        <Route path="/text-editor" element={<Texteditor />} />
        <Route path="/counter" element={<Counter />} />
        <Route path="/scrollbar" element={<Scrollbar />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/sticky-note" element={<Stickynote />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/horizontal-timeline" element={<Horizontaltimeline />} />
        <Route path="/form-wizard" element={<Formwizard />} />
        <Route path="/contact-messages" element={<ContactMessage />} />
        <Route path="/role-management" element={<RoleManagement />} />
        <Route path="/add-role" element={<AddRole />} />
        <Route path="/staff-management" element={<StaffManagement />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/role-details" element={<RoleDetails />} />
        <Route path="/curriculum-management" element={<Curriculum />} />
        <Route path="/add-curriculum" element={<AddCurriculum />} />
        <Route path="/edit-curriculum" element={<EditCurriculum />} />

        <Route
          path="/subscription-management"
          element={<SubscriptionManagement />}
        />
        <Route
          path="/subscription-management/add-subscription"
          element={<AddSubscription />}
        />
        <Route
          path="/subscription-management/edit-subscription/:subscriptionId"
          element={<EditSubscription />}
        />
        <Route
          path="/subscription-management/view-subscription-details/:subscriptionId"
          element={<SubscriptionDetails />}
        />
        <Route path="/coupon-management" element={<PromotionManagement />} />
        <Route path="/coupon-management/add-coupon" element={<AddCoupon />} />
        <Route
          path="/coupon-management/edit-coupon/:couponId"
          element={<EditCoupon />}
        />
        <Route
          path="/internship-management"
          element={<InternshipManagement />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
