export const loginValidation = (email, Password, setError, error) => {
  if (email === "" || Password === "") {
    setError({
      ...error,
      email: email === "" ? "Please enter Email Id" : "",
      Password: Password === "" ? "Please enter Password" : "",
    });
    return false;
  } else {
    return true;
  }
};

export const AddCurriculumValidation = ({ state, error, setError }) => {
  let { curriculumTitle, sessionTitle, description } = state;
  if (curriculumTitle === "" || sessionTitle === "" || description === "") {
    setError({
      ...error,
      curriculumTitle:
        curriculumTitle === "" && "Please Enter Curriculum Title",
      sessionTitle: sessionTitle === "" && "Please Enter Session Title",
      description: description === "" && "Please Enter Session Description",
    });
    return false;
  } else {
    return true;
  }
};

export const AddSubscriptionValidation = ({ state, error, setError }) => {
  let {
    name,
    description,
    monthlyPrice,
    yearlyPrice,
    startTimestamp,
    // type,
    shortDescription,
    tier,
  } = state;
  if (
    name === "" ||
    description === "" ||
    monthlyPrice === "" ||
    yearlyPrice === "" ||
    startTimestamp === "" ||
    // type === "" ||
    shortDescription === "" ||
    tier === ""
  ) {
    setError({
      ...error,
      name: name === "" && "Please Enter Subscription Title",
      description:
        description === "" && "Please Enter Subscription Description",
      monthlyPrice: monthlyPrice === "" && "Please Enter Monthly Price ",
      yearlyPrice: yearlyPrice === "" && "Please Enter Yearly Price",
      startTimestamp: startTimestamp === "" && "Please Enter Start Date",
      // type: "Please Select Subscription Type",
      shortDescription:
        shortDescription === "" &&
        "Please Enter Description About Subscription",
      tier: tier === "" && "Please Enter Subscription Tier",
    });
    return false;
  } else {
    return true;
  }
};

export const couponValidation = ({ state, error, setError }) => {
  const {
    code,
    type,
    discountType,
    discountValue,
    startTimestamp,
    perUserLimit,
    totalUsageLimit,
    description,
  } = state;

  if (
    code === "" ||
    type === "" ||
    discountType === "" ||
    discountValue === "" ||
    startTimestamp === "" ||
    perUserLimit === "" ||
    totalUsageLimit === "" ||
    description === ""
  ) {
    setError({
      ...error,
      code: code === "" && "Please Enter Coupon Code",
      type: type === "" && "Please Select Promotion Type",
      discountType: discountType === "" && "Please Select Discount Type",
      discountValue: discountValue === "" && "Please Enter Discount Value",
      startTimestamp: startTimestamp === "" && "Please Enter Coupon Start Date",
      perUserLimit: perUserLimit === "" && "Please Enter User Limit",
      totalUsageLimit:
        totalUsageLimit === "" && "Please Enter Total Usage Limit",
      description: description === "" && "Please Enter description",
    });
    return false;
  } else {
    return true;
  }
};
