import React from "react";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { Box, Card, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { fontFamily } from "../utils/enum";
import { Description } from "@mui/icons-material";

const InternshipManagement = () => {
  return (
    <div>
      <Helmet>
        <title>Internship Management</title>
      </Helmet>
      <Sidebar />
      <Header />
      <Box className="page-wrapper">
        <Box sx={{ p: 5 }}>
          <Card></Card>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Description />
            <Typography
              sx={{
                fontSize: 20,
                fontFamily: fontFamily.roboto,
                fontWeight: 600,
              }}
            >
              Internship Management
            </Typography>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default InternshipManagement;
