import internshipSecuredApi from "./config";
export const internshipController = {
  addCurriculum: async (data) => {
    try {
      let result = await internshipSecuredApi.internshipSecuredApi.post(
        "api/curriculum/addOrUpdateCurriculum",
        data
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCurriculum: async (data) => {
    let { page, pageSize } = data;
    let { search, status } = data;
    try {
      let result = await internshipSecuredApi.internshipSecuredApi.post(
        `/api/curriculum/getCurriculums?page=${
          page === 0 ? 1 : page
        }&pageSize=${pageSize}`,
        {
          search,
          status,
        }
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  getCurriculumById: async (data) => {
    try {
      let result = await internshipSecuredApi.internshipSecuredApi.get(
        `/api/curriculum/getById/${data}`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
  activeInActiveStatusChange: async (data) => {
    try {
      let result = await internshipSecuredApi.internshipSecuredApi.get(
        `/api/curriculum/inactive/${data.id}/${data.status}`
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
};
