import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import recentinvoices from "../json/recentinvoices";
import recentestimates from "../json/recentestimates";
// import ApexCharts from 'apexcharts'
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
// import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import Authcontrollers from "../api/authController";
import Loading from "../_components/Loading";
import userController from "../api/users";
import { USER_GROUP } from "../utils/enum";
import moment from "moment";
import { ArrowUpward, Business, Person, Wc } from "@mui/icons-material";
import { Table } from "react-bootstrap";
import { Grid } from "@mui/material";
import UserCard from "../_components/userCard";
import { Helmet } from "react-helmet";
// import vroarstaff from "../assets/img/icons/paren"

const Dashboard = () => {
  const location = useLocation();
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [list, setList] = useState([]);
  const [length, setLength] = useState("");

  const student_list = () => {
    let value = {
      page: 1,
      pageSize: 5,
      group: USER_GROUP.STUDENT,
    };
    userController
      .getUsers(value)
      .then((res) => {
        // console.log("testst", res);

        setList(res.data.data.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    student_list();
  }, []);
  const [companylength, setCompanylength] = useState("");
  const [CompanyList, setCompanyList] = useState([]);

  const Company_list = () => {
    let value = {
      page: 1,
      pageSize: 5,
      group: USER_GROUP.COMPANY,
    };
    userController
      .getUsers(value)
      .then((res) => {
        setCompanylength(res.data.data.totalDocs);
        setCompanyList(res.data.data.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const [totalParent, setTotalParent] = useState("");
  const [parentList, setParentList] = useState([]);
  const getParent = () => {
    let value = {
      page: 1,
      pageSize: 5,
      group: USER_GROUP.PARENT,
    };
    userController
      .getUsers(value)
      .then((res) => {
        setTotalParent(res.data.data.totalDocs);
        setParentList(res.data.data.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [count, setCount] = useState({});
  const getProfileCount = () => {
    userController
      .getProfileCount()
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Company_list();
    getParent();
    getProfileCount();
  }, []);

  const salesOptions = {
      colors: ["#7638ff", "#fda600"],
      chart: {
        type: "bar",
        fontFamily: "Poppins, sans-serif",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      series: [
        {
          name: "Received",
          type: "column",
          data: [70, 150, 80, 180, 150, 175, 201, 60, 200, 120, 190, 160, 50],
        },
        {
          name: "Pending",
          type: "column",
          data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16, 80],
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    invoiceOptions = {
      colors: ["#7638ff", "#ff737b", "#fda600", "#1ec1b0"],
      chart: {
        fontFamily: "Poppins, sans-serif",
        height: 350,
        type: "donut",
      },
      series: [55, 40, 20, 10],
      labels: ["Paid", "Unpaid", "Overdue", "Draft"],
      legend: { show: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    recentestimates_ = recentestimates,
    recentinvoices_ = recentinvoices;

  const counts = [
    {
      heading: "Total Students",
      userCount: count?.STUDENT?.userCount,
      newUserCount: count?.STUDENT?.newUserCount,
      icon: <Person />,
      url: "/student",
    },
    {
      heading: "Total Parents",
      userCount: count?.PARENT?.userCount,
      newUserCount: count?.PARENT?.newUserCount,
      icon: <Wc />,
      url: "/parent",
    },
    {
      heading: "Total Companies",
      userCount: count?.COMPANY?.userCount,
      newUserCount: count?.COMPANY?.newUserCount,
      icon: <Business />,
      url: "/company  ",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <Grid container spacing={2} mb={2}>
              {counts.map((val, i) => (
                <Grid item lg={4} key={i}>
                  <UserCard
                    icon={val.icon}
                    userCount={val.userCount}
                    newUserCount={val.newUserCount}
                    heading={val.heading}
                  />
                </Grid>
              ))}
            </Grid>
            {/* <div className="row">
              <div className="col-xl-4 col-sm-6 col-12">
                <NavLink to={"/student"}>
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-1">
                          <i className="fas fa-user"></i>
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">Total Students</div>
                          <div className="dash-counts">
                            <p>
                              {count && count.STUDENT
                                ? count.STUDENT.userCount
                                : "-"}
                            </p>
                          </div>
                          <p className="dash-title ">
                            {moment().format("MMMM")}, {moment().format("YYYY")}
                          </p>
                          <p className="f-13 text-success">
                            <span>
                              {count && count.STUDENT
                                ? count.STUDENT.newUserCount
                                : "-"}
                            </span>
                            <ArrowUpward fontSize="sm" />
                          </p>
                        </div>
                      </div>

                      <p className="text-muted mt-3 mb-0"></p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <NavLink to={"/parent"}>
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-2">
                          <i class="fa-solid fa-children"></i>
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">Total Parents</div>
                          <div className="dash-counts">
                            <p>
                              {count && count.PARENT
                                ? count.PARENT.userCount
                                : "-"}
                            </p>
                          </div>
                          <p className="dash-title ">
                            {moment().format("MMMM")}, {moment().format("YYYY")}
                          </p>
                          <p className="f-13 text-success">
                            <span>
                              {count && count.PARENT
                                ? count.PARENT.newUserCount
                                : "-"}
                            </span>
                            <ArrowUpward fontSize="sm" />
                          </p>
                        </div>
                      </div>

                      <p className="text-muted mt-3 mb-0"></p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <NavLink to={"/company"}>
                  <div className="card">
                    <div className="card-body">
                      <div className="dash-widget-header">
                        <span className="dash-widget-icon bg-3">
                          <i className="fas fa-building"></i>
                        </span>
                        <div className="dash-count">
                          <div className="dash-title">Total Companies</div>
                          <div className="dash-counts">
                            <p>
                              {count && count.COMPANY
                                ? count.COMPANY.userCount
                                : "-"}
                            </p>
                          </div>
                          <p className="dash-title ">
                            {moment().format("MMMM")}, {moment().format("YYYY")}
                          </p>
                          <p className="f-13 text-success">
                            <span>
                              {count && count.COMPANY
                                ? count.COMPANY.newUserCount
                                : "-"}
                            </span>
                            <ArrowUpward fontSize="sm" />
                          </p>
                        </div>
                      </div>

                      <p className="text-muted mt-3 mb-0"></p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div> */}
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      {" "}
                      <div className="d-flex align-items-center">
                        <div className="square-box"></div>
                        <h5 className="user-title ">Student</h5>
                      </div>
                      <Link to="/student" className="btn btn-primary me-2">
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <Loading
                        type="bars"
                        height={25}
                        width={25}
                        className="m-auto"
                        color="f15d17"
                      />
                    ) : (
                      <Table bordered hover>
                        <thead className="thead-light">
                          <tr>
                            <th className="fw-700">Name</th>
                            <th className="fw-700">Last Login</th>
                            <th className="fw-700">Registered on</th>
                          </tr>
                        </thead>
                        <tbody>
                          {list.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ width: "250px" }}>
                                  <h2 className="text-capitalize">
                                    {item.name}
                                  </h2>
                                </td>

                                <td>
                                  {moment(item.lastLogin).format("MMM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(item.createdAt).format("MMM-DD-YYYY")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      {" "}
                      <div className="d-flex align-items-center">
                        <div className="square-box"></div>
                        <h5 className="user-title ">Parent</h5>
                      </div>
                      <Link to="/student" className="btn btn-primary me-2">
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <Loading
                        type="bars"
                        height={25}
                        width={25}
                        className="m-auto"
                        color="f15d17"
                      />
                    ) : (
                      <Table bordered hover>
                        <thead className="thead-light">
                          <tr>
                            <th className="fw-700">Name</th>
                            <th className="fw-700">Last Login</th>
                            <th className="fw-700">Registered on</th>
                          </tr>
                        </thead>
                        <tbody>
                          {parentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ width: "250px" }}>
                                  <h2 className="text-capitalize">
                                    {item.name}
                                  </h2>
                                </td>

                                <td>
                                  {moment(item.lastLogin).format("MMM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(item.createdAt).format("MMM-DD-YYYY")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                      {" "}
                      <div className="d-flex align-items-center">
                        <div className="square-box"></div>
                        <h5 className="user-title ">COMPANY</h5>
                      </div>
                      <Link to="/student" className="btn btn-primary me-2">
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    {loading ? (
                      <Loading
                        type="bars"
                        height={25}
                        width={25}
                        className="m-auto"
                        color="f15d17"
                      />
                    ) : (
                      <Table bordered hover>
                        <thead className="thead-light">
                          <tr>
                            <th className="fw-700">Name</th>
                            <th className="fw-700">Last Login</th>
                            <th className="fw-700">Registered on</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CompanyList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ width: "250px" }}>
                                  <h2 className="text-capitalize">
                                    <p className="ps-2">{item.name}</p>
                                  </h2>
                                </td>

                                <td>
                                  {moment(item.lastLogin).format("MMM-DD-YYYY")}
                                </td>
                                <td>
                                  {moment(item.createdAt).format("MMM-DD-YYYY")}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Student</h5>
                      </div>
                      <div className="col-auto">
                        <Link to="/student" className="btn btn-primary me-2">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="table-responsive"
                      style={{ height: "270px" }}
                    >
                      {loading ? (
                        <Loading
                          type="bars"
                          height={25}
                          width={25}
                          className="m-auto"
                          color="f15d17"
                        />
                      ) : (
                        <Table bordered hover>
                          <thead className="thead-light">
                            <tr>
                              <th>Name</th>
                              <th>Last Login</th>
                              <th>Registered on</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <h2 className="text-capitalize">
                                      <p className="ps-2">{item.name}</p>
                                    </h2>
                                  </td>

                                  <td>
                                    {moment(item.lastLogin).format(
                                      "MMM-DD-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "MMM-DD-YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Company</h5>
                      </div>
                      <div className="col-auto">
                        <Link to="/company" className="btn btn-primary me-2">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="table-responsive"
                      style={{ height: "270px" }}
                    >
                      {loading ? (
                        <Loading
                          type="bars"
                          width={25}
                          height={25}
                          className="m-auto"
                          color="f15d17"
                        />
                      ) : (
                        <Table bordered hover>
                          <thead className="thead-light">
                            <tr>
                              <th>Company</th>
                              <th>Last Login</th>
                              <th>Registered on</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CompanyList.map((item, index) => {
                              let status;
                              if (item.status === "Sent") {
                                status = (
                                  <span className="badge bg-info-light text-info">
                                    {item.status}
                                  </span>
                                );
                              } else if (item.status === "Expired") {
                                status = (
                                  <span className="badge bg-warning-light text-warning">
                                    {item.status}
                                  </span>
                                );
                              } else {
                                status = (
                                  <span className="badge bg-success-light">
                                    {item.status}
                                  </span>
                                );
                              }
                              return (
                                <tr key={index}>
                                  <td>
                                    <h2 className="text-capitalize">
                                      {item.company.companyName}
                                    </h2>
                                  </td>
                                  <td>
                                    {moment(item.lastLogin).format(
                                      "MMM-DD-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item.lastLogin).format(
                                      "MMM-DD-YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Parent</h5>
                      </div>
                      <div className="col-auto">
                        <Link to="/parent" className="btn btn-primary me-2">
                          View All
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="table-responsive"
                      style={{ height: "270px" }}
                    >
                      {loading ? (
                        <Loading
                          type="bars"
                          height={25}
                          width={25}
                          className="m-auto"
                          color="f15d17"
                        />
                      ) : (
                        <Table bordered hover>
                          <thead className="thead-light">
                            <tr>
                              <th>Parents</th>
                              <th>Last Login</th>
                              <th>Registered on</th>
                            </tr>
                          </thead>
                          <tbody>
                            {parentList.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <h2 className="text-capitalize">
                                      {item.name}
                                    </h2>
                                  </td>
                                  <td>
                                    {moment(item.lastLogin).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
